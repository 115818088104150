import { ThemeProvider } from "@material-ui/core";
import moment from "moment";
import "./App.css";
import { GetCurrentLocale } from "./helpers";
import Routers from "./routers";
import { theme } from "./themes";

const App = () => {
  moment.locale(GetCurrentLocale());
  return (
    <ThemeProvider theme={theme()}>
      <Routers />
    </ThemeProvider>
  );
};
export default App;
