import moment from "moment";
import "moment-duration-format";
import "moment/min/locales";
import { useTranslation } from "react-i18next";
import { defaultLanguage } from "../i18n";

// Get current locale
export const GetCurrentLocale = () => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language.toLowerCase();
  const locales = moment.locales()?.map(l => l.toLowerCase());
  if (locales?.includes(currentLocale)) {
    return currentLocale;
  }
  const currentLocaleSplited = currentLocale?.split("-")[0];
  if (locales?.includes(currentLocaleSplited)) {
    return currentLocaleSplited;
  }
  // No current locale found, return english
  return defaultLanguage;
};
