import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        paddingBottom: 100,
      }}>
      <label style={{ fontSize: 50, fontWeight: "bold" }}>{t("404ErrorTitle")}</label>
    </div>
  );
};
export default NotFound;
