import { createTheme } from "@material-ui/core";

export const theme = (fgdColor?: string, bgdColor?: string) => {
  const foregroundColor = "#1380C8";
  const backgroundColor = "#e8f4fd";

  return createTheme({
    palette: {
      primary: {
        main: foregroundColor,
        light: foregroundColor,
        dark: foregroundColor,
      },
      action: {
        active: foregroundColor,
      },
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          color: foregroundColor,
        },
      },
    },
  });
};
