import AppStoreBadgeEN from "./../../assets/DownloadAppStoreBadgeEN.svg";
import AppStoreBadgeFR from "./../../assets/DownloadAppStoreBadgeFR.svg";
import PlayStoreBadgeEN from "./../../assets/DownloadPlayStoreBadgeEN.png";
import PlayStoreBadgeFR from "./../../assets/DownloadPlayStoreBadgeFR.png";
import AgendaViewFR from "./../../assets/AgendaViewFR.jpg";
import { useTranslation } from "react-i18next";
import file from "./../../app.json";
import { GetCurrentLocale } from "../../helpers";

const TitleWithValue = ({ title, value }: { title: string; value: string }) => (
	<div style={{ display: "flex", flexDirection: "row" }}>
		<span style={{ fontWeight: "bold" }}>{title}</span>
		<span>{value}</span>
	</div>
);
const Home = () => {
	const { t } = useTranslation();
	const locale = GetCurrentLocale()?.toLowerCase();
	return (
		<div
			style={{
				display: "flex",
				flex: 1,
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}>
			<h1>{t("OnlineBookingSoftware")}</h1>
			<img src={AgendaViewFR} alt="Agenda view" height="500px" />
			<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
				<a style={{ display: "flex", flex: 1, height: "50px" }} href={file.appStoreLink}>
					<img src={locale === "fr" ? AppStoreBadgeFR : AppStoreBadgeEN} alt="App store badge" />
				</a>

				<a style={{ display: "flex", flex: 1, height: "74px", width: "250px" }} href={file.playStoreLink}>
					<img src={locale === "fr" ? PlayStoreBadgeFR : PlayStoreBadgeEN} alt="Play store badge" />
				</a>
			</div>
			<div style={{ display: "flex", flexDirection: "column", marginTop: 15, alignItems: "center" }}>
				<span style={{ fontWeight: "bold" }}>Contact details:</span>
				<br />
				<TitleWithValue title="Company name:" value="&nbsp;Previsy - FZCO" />
				<TitleWithValue title="Email:" value="&nbsp;contact@previsy.com" />
				<TitleWithValue title="Company address:" value="&nbsp;Dubai Silicon Oasis, DDP, Building A1, Dubai, United Arab Emirates" />
				<TitleWithValue title="Director:" value="&nbsp;Sami Amin RIMANI" />
			</div>
		</div>
	);
};
export default Home;
